import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { IconName, OptionModel } from '@san/tools/models';
import { SanLoaderDirective } from '@san/tools/shared';
import { SanBlocComponent } from '@san/tools/ui/bloc';
import { SanButtonIconComponent } from '@san/tools/ui/button-icon';
import { SanCheckBoxComponent } from '@san/tools/ui/checkbox';
import { SanSelectComponent } from '@san/tools/ui/select';
import { map, take, tap } from 'rxjs';
import { PubliqueService } from '@shared/api/publique.service';
import { RdvService } from '@shared/api/rdv.service';
import { AvailablePeriodsComponent } from '@shared/components/available-periods/available-periods.component';
import { HospitalComponent } from '@shared/components/hospital/hospital.component';
import { Etablissement } from '@shared/models/entity/etablissement';
import { Medecin } from '@shared/models/entity/medecin';
import { Patient } from '@shared/models/entity/patient';
import { Prestation } from '@shared/models/entity/prestation';
import { Rdv } from '@shared/models/entity/rdv';
import { RdvStep } from '@shared/models/enum/rdv.enum';
import { DisplayValuePipe, LongDatePipe } from '@shared/pipes/object.pipe';
import { ApplicationService } from '@shared/services/application.service';
import { NavigationService } from '@shared/services/navigation.service';
import { UserService } from '@shared/services/user.service';
import { ObjectUtility } from '@shared/utils/object.utils';

@Component({
  selector: 'rdv-booking',
  templateUrl: './booking.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./booking.component.scss'],
  imports: [
    CommonModule,
    SanBlocComponent,
    LongDatePipe,
    SanSelectComponent,
    SanButtonIconComponent,
    DisplayValuePipe,
    HospitalComponent,
    AvailablePeriodsComponent,
    SanLoaderDirective,
    TranslatePipe,
    FlexLayoutModule,
    SanCheckBoxComponent,
    FormsModule,
    RouterLink,
    ReactiveFormsModule,
  ],
})
export class BookingComponent implements OnInit {
  private readonly navigationService = inject(NavigationService);
  private readonly userService = inject(UserService);
  private readonly rdvService = inject(RdvService);
  private readonly appService = inject(ApplicationService);
  private readonly publiqueService = inject(PubliqueService);

  readonly IconName = IconName;
  readonly RdvStep = RdvStep;
  step: number = window?.history?.state?.step;
  medecin = window?.history?.state?.medecin;
  etablissement = window?.history?.state?.etablissement;
  date: Date;
  prestations: OptionModel<Prestation>[] = [];
  prestation: OptionModel<Prestation>;
  patient: Patient;
  rdvRequest: Partial<Rdv>;
  loading = signal(false);
  confirm: boolean = false;

  constructor() {
    if (window?.history?.state?.date) {
      this.date = new Date(window.history.state.date);
    }

    // Come from login
    if (this.step === RdvStep.RESUME) {
      this.rdvRequest = window?.history?.state?.request;
      this.etablissement = window?.history?.state?.etablissement;
      this.medecin = this.rdvRequest?.medecin;
    }

    // come from search
    if (!this.intervenant?.id) {
      this.navigationService.goHome();
    }
  }

  get intervenant() {
    return this.etablissement ?? this.medecin;
  }

  ngOnInit(): void {
    this.loading.set(true);
    this.publiqueService
      .getPrestations(
        this.etablissement?.id ?? this.medecin?.utilisateur.etablissement?.id
      )
      .pipe(
        take(1),
        map((resp) =>
          resp.data?.map((p: Prestation) => ({
            value: p.id.toString(),
            label: ObjectUtility.formatPrestation(p),
            data: p,
          }))
        ),
        tap(() => this.loading.set(false))
      )
      .subscribe((prestations) => {
        this.prestations = prestations;
        this.setResumeData();
      });
  }

  setResumeData(): void {
    ApplicationService.timeOut(() => {
      this.patient = this.userService.connectedPatient();
      if (this.step === RdvStep.RESUME) {
        this.prestation = this.prestations.find(
          (p) => this.rdvRequest.prestation?.id === p.value
        );
        this.date = this.rdvRequest.debut;
      }
    });
  }

  get isPro() {
    return !this.etablissement?.id;
  }

  get header() {
    return this.isPro
      ? UserService.getFullName(this.intervenant.utilisateur)
      : this.intervenant.libelle;
  }

  isMedecin(data: Medecin | Etablissement): data is Medecin {
    return this.isPro;
  }

  isEtablissment(data: Medecin | Etablissement): data is Etablissement {
    return !this.isPro;
  }

  refreshTimes(state: boolean) {
    if (state) {
      this.step = RdvStep.DATE;
      this.appService.resizingWindow.next(null);
    }
  }

  changeDate(date: Date) {
    this.date = date;
    this.step += 1;
  }

  get enableNextStep() {
    switch (this.step) {
      case RdvStep.DATE:
        return !!this.date;
      case RdvStep.RAISON:
        return !!this.prestation?.value;
      default:
        return this.rdvIsValid();
    }
  }

  rdvIsValid(): boolean {
    return !!this.intervenant?.id && !!this.date && !!this.prestation?.value;
  }

  changeStep(next: boolean = true) {
    this.step += next ? 1 : -1;
    this.confirm = false;
    if (this.step === RdvStep.DATE) {
      this.appService.resizingWindow.next(null);
    } else if (this.step === RdvStep.RESUME && !this.patient) {
      this.book();
    }
  }

  createRequest() {
    this.rdvRequest = {
      medecin: this.isPro ? this.intervenant : null,
      debut: this.date,
      prestation: this.prestation.data as Prestation,
      patient: this.patient,
    };
  }

  book() {
    this.createRequest();
    const valid = this.rdvService.rdvRequestIsValid(this.rdvRequest, false);
    if (!this.patient?.id && valid) {
      this.userService.logout();
      this.navigationService.navigate('/account/patient/login', {
        state: {
          request: this.rdvRequest,
          etablissement: this.isPro ? this.intervenant : null,
        },
      });
    }

    if (valid) {
      this.rdvService.bookRdv(this.rdvRequest, () =>
        this.navigationService.goHome()
      );
    }
  }
}
