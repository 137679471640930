<form [formGroup]="searchForm" (keyup.enter)="searchMedecin()">
  <div fxLayout.lt-md="column" fxLayout.gt-sm="row" fxLayoutAlign="start center" fxLayoutGap="5px"
    class="search-section">
    <san-autocomplete #term idControl="search-term-input" class="full-width" fxFlex.gt-sm="60" labelField="libelle"
      valueField="id" placeholder="home.search-form.term-placeholder" [sources]="termOptions" [manageObject]="true"
      formControlName="term">
    </san-autocomplete>
    <san-autocomplete #city idControl="search-ville-input" class="full-width" [sources]="citiesOptions"
      labelField="libelle" valueField="id" [manageObject]="true" [withIcon]="true" suffix="home.search-form.city-suffix"
      (clickOnSuffix)="locateMe()" [icon]="IconName.LOCATE_ME" fxFlex.gt-sm="30"
      placeholder="home.search-form.city-placeholder" formControlName="city"></san-autocomplete>
    <san-button-icon idControl="search-btn" (onclick)="searchMedecin()" [icon]="IconName.SEARCH" classes="full-width"
      label="shared.rechercher"></san-button-icon>
  </div>
</form>
