import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslatePipe } from '@ngx-translate/core';
import { IconName } from '@san/tools/models';
import { SanButtonIconComponent } from '@san/tools/ui/button-icon';
import { PhotoComponent } from '../../photo/photo.component';
import { Utilisateur } from './../../../models/entity/Utilisateur';
import { Medecin } from './../../../models/entity/medecin';
import { RdvStep } from './../../../models/enum/rdv.enum';
import {
  DisplayListPipe,
  DisplayValuePipe,
} from './../../../pipes/object.pipe';
import { FullNamePipe } from './../../../pipes/user.pipe';
import { NavigationService } from './../../../services/navigation.service';

@Component({
  selector: 'rdv-user-resume',
  templateUrl: './user-resume.component.html',
  styleUrls: ['./user-resume.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FlexLayoutModule,
    DisplayValuePipe,
    DisplayListPipe,
    PhotoComponent,
    SanButtonIconComponent,
    TranslatePipe,
    FullNamePipe,
  ],
})
export class UserResumeComponent implements OnInit {
  private readonly navigationService = inject(NavigationService);

  @Input() pro: Utilisateur;
  @Input() showRdvButton: boolean = false;
  readonly IconName = IconName;
  medecin: Medecin;

  ngOnInit(): void {
    this.medecin = {
      ...this.pro.medecin,
      utilisateur: { ...this.pro, medecin: null },
    };
  }

  openProfile() {
    this.navigationService.openInNewTab(`/home/doctors/${this.medecin?.id}`);
  }

  openBooking() {
    this.navigationService.openBooking(this.medecin, null, RdvStep.DATE);
  }
}
