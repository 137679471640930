import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { IconName, OptionModel, Page } from '@san/tools/models';
import { SanLoaderDirective } from '@san/tools/shared';
import { SanButtonToggleComponent } from '@san/tools/ui/button-toggle';
import { SanPaginationComponent } from '@san/tools/ui/pagination';
import { LatLng } from 'leaflet';
import { cloneDeep, uniqBy } from 'lodash-es';
import { debounceTime, forkJoin, take, tap } from 'rxjs';
import { ReservationSearchComponent } from '../reservation-search/reservation-search.component';
import { PubliqueService } from '@shared/api/publique.service';
import { MapComponent } from '@shared/components/map/map.component';
import { ReservationBlocComponent } from '@shared/components/reservation-bloc/reservation-bloc.component';
import { Etablissement } from '@shared/models/entity/etablissement';
import { Medecin } from '@shared/models/entity/medecin';
import { GlobalSearchTerm } from '@shared/models/interfaces/app-conf.interface';
import { MarkerItem } from '@shared/models/interfaces/map.interface';
import { SessionKey } from '@shared/models/session.keys';
import { ApplicationService } from '@shared/services/application.service';
import { MapService } from '@shared/services/map.service';
import { NavigationService } from '@shared/services/navigation.service';
import { SsrService } from '@shared/services/ssr.service';

@Component({
  selector: 'rdv-rdv-found-medecin',
  templateUrl: './rdv-found-medecin.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./rdv-found-medecin.component.scss'],
  imports: [
    CommonModule,
    ReservationSearchComponent,
    SanLoaderDirective,
    SanButtonToggleComponent,
    MapComponent,
    ReservationBlocComponent,
    TranslatePipe,
    FormsModule,
    ReactiveFormsModule,
    SanPaginationComponent,
    FlexLayoutModule,
  ],
})
export class RdvFoundMedecinComponent implements OnInit {
  private readonly navigationService = inject(NavigationService);
  private readonly publiqueService = inject(PubliqueService);
  private readonly changeDetector = inject(ChangeDetectorRef);
  private readonly ssrService = inject(SsrService);

  searchTerm: GlobalSearchTerm;
  readonly IconName = IconName;
  medecins: Medecin[] = [];
  etablissements: Etablissement[] = [];
  mapEtablissements: MarkerItem[] = [];
  loading = signal(true);
  tabs: OptionModel<IconName>[] = [
    { value: IconName.DOCTOR, label: 'shared.medecins' },
    { value: IconName.HOSPITAL, label: 'shared.etablissements' },
    { value: IconName.MAP, label: 'core.map.carte' },
  ];
  selectedTab: IconName = IconName.DOCTOR;

  nbMedecins = 0;
  nbEtablissements = 0;

  constructor() {
    if (this.ssrService.isBrowser()) {
      this.searchTerm = ApplicationService.readSession(
        SessionKey.MEDECIN_SEARCH_TERM
      );
    }
    if (!this.searchTerm) {
      this.navigationService.goHome();
    }
  }

  ngOnInit(): void {
    this.search();
  }

  search(term?: GlobalSearchTerm) {
    if (term) {
      this.searchTerm = term;
    }
    this.loading.set(true);
    if (this.ssrService.isBrowser()) {
      ApplicationService.storeSession(
        SessionKey.MEDECIN_SEARCH_TERM,
        this.searchTerm
      );
    }
    this.paginateMedecins();
    this.paginateEtablissements();
  }

  paginateMedecins(page: Page = Page.build()) {
    this.publiqueService
      .searchMedecins({ ...this.searchTerm, page })
      .pipe(
        tap(() => this.loading.set(false)),
        take(1)
      )
      .subscribe((medecinResp) => {
        this.medecins = medecinResp.data;
        this.nbMedecins = medecinResp.size;
        this.updateMap(this.etablissements, this.medecins);
      });
  }

  paginateEtablissements(page: Page = Page.build()) {
    this.publiqueService
      .searchEtablissements({ ...this.searchTerm, page })
      .pipe(
        tap(() => this.loading.set(false)),
        take(1)
      )
      .subscribe((etablissementResp) => {
        this.etablissements = etablissementResp.data;
        this.nbEtablissements = etablissementResp.size;
        this.updateMap(this.etablissements, this.medecins);
      });
  }

  searchOnMapMoved(center: LatLng) {
    const term: GlobalSearchTerm = {
      gps: { gps_lat: center.lat.toString(), gps_lng: center.lng.toString() },
      page: Page.build(),
    };
    forkJoin([
      this.publiqueService.searchEtablissements(term),
      this.publiqueService.searchMedecins(term),
    ])
      .pipe(
        debounceTime(1000),
        tap(([resEtab, resMed]) => this.updateMap(resEtab.data, resMed.data)),
        take(1)
      )
      .subscribe();
  }

  updateMap(etablissements: Etablissement[], medecins: Medecin[]) {
    const data = [
      ...etablissements,
      ...medecins.map((m) => m.utilisateur?.etablissement),
    ].filter((d) => d?.gps_lat && d?.gps_lng);
    this.mapEtablissements = cloneDeep(
      uniqBy(data, 'id').map(
        (et) =>
          ({
            id: et.id,
            position: MapService.gpsToLatLng(et.gps_lat, et.gps_lng),
            tooltip: et.libelle,
            label: et.libelle,
            data: et,
          } as MarkerItem)
      )
    );
    this.changeDetector.detectChanges();
  }
}
