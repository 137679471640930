<div [isLoading]="reactiveService.loading()" class="doctor-profil middle">
  <h3 class="text-primary" fxLayout="row" fxLayoutAlign="start center">
    <san-icon [name]="IconName.DOCTOR"></san-icon>
    {{'profil-medecin.title' | translate}}
  </h3>
  <div fxLayout="row wrap" fxLayoutGap="16px grid">
    <div fxFlex="50%" fxFlex.lt-md="100%">
      <div fxLayout="column" fxLayoutGap="16px">

        <san-bloc header="profil-medecin.informations" [icon]="IconName.ID_CARD">
          <div content>
            <rdv-user-info [user]="pro" [editable]="false"></rdv-user-info>
          </div>
        </san-bloc>

        <san-bloc header="profil-medecin.presentation" [icon]="IconName.IMAGE_PORTRAIT">
          <div content>
            <div [innerHTML]="pro?.medecin.presentation | safeHtml"></div>
          </div>
        </san-bloc>

        <san-bloc header="profil-medecin.etablissement" [icon]="IconName.HOSPITAL">
          <div content>
            <rdv-hospital [etablissement]="pro?.etablissement"></rdv-hospital>
          </div>
        </san-bloc>

        <san-bloc *ngIf="isMedecin" header="profil-medecin.diplomes" [icon]="IconName.GRADUATE">
          <div content>
            <rdv-medecin-diplome [diplomes]="diplomes" [readonly]="true"></rdv-medecin-diplome>
          </div>
        </san-bloc>

      </div>
    </div>
    <div fxFlex="50%" fxFlex.lt-md="100%">
      <div fxLayout="column" fxLayoutGap="16px">

        <san-bloc header="profil-medecin.specialites" [icon]="IconName.MEDAL">
          <div content>
            <san-list-view [items]="specialites " direction="horizontal"></san-list-view>
          </div>
        </san-bloc>

        <san-bloc header="profil-medecin.prestations" [icon]="IconName.STAR">
          <div content>
            <san-list-view [items]="prestations " direction="vertical-fw"></san-list-view>
          </div>
        </san-bloc>

        <san-bloc header="profil-medecin.couverture" [icon]="IconName.FILE_WAVE">
          <div content>
            <san-list-view [items]="couvertures" direction="horizontal"></san-list-view>
          </div>
        </san-bloc>

        <san-bloc header="profil-medecin.moyen" [icon]="IconName.FILE_INVOICE">
          <div content>
            <san-list-view [items]="moyens " direction="horizontal"></san-list-view>
          </div>
        </san-bloc>

      </div>
    </div>
  </div>
</div>
