import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
  signal,
} from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslatePipe } from '@ngx-translate/core';
import { IconName } from '@san/tools/models';
import { SanLoaderDirective } from '@san/tools/shared';
import { SanButtonComponent } from '@san/tools/ui/button';
import { SanIconComponent } from '@san/tools/ui/icon';
import { BaseComponent } from '@san/tools/utils';
import { cloneDeep } from 'lodash-es';
import { debounceTime, take, takeUntil, tap } from 'rxjs';
import { PubliqueService } from '../../api/publique.service';
import { AppointmentPeriod } from '../../models/interfaces/rdv.interface';
import { PeriodRequest } from '../../models/request/rdv.request';
import { DisplayHourPipe, LongDatePipe } from '../../pipes/object.pipe';
import { ApplicationService } from '../../services/application.service';
import { DateService } from '../../services/date.service';

@Component({
  selector: 'rdv-available-periods',
  templateUrl: './available-periods.component.html',
  styleUrls: ['./available-periods.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FlexLayoutModule,
    TranslatePipe,
    SanLoaderDirective,
    SanIconComponent,
    LongDatePipe,
    SanButtonComponent,
    DisplayHourPipe,
  ],
})
export class AvailablePeriodsComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  private readonly publiqueService = inject(PubliqueService);
  private readonly appService = inject(ApplicationService);
  private readonly elt = inject(ElementRef);

  @Input() etalissmentOrProId: string;
  @Input() isPro: boolean; // Is medecin if true
  param: PeriodRequest;
  periods: AppointmentPeriod[] = [];
  readonly IconName = IconName;
  start: Date = DateService.trunk();
  width: number = window?.innerWidth;
  nbDay: number = 7;
  loading = signal(true);
  more: boolean = false;
  @Output() clickOnTime: EventEmitter<Date> = new EventEmitter<Date>();

  ngOnInit(): void {
    this.appService.resizingWindow
      .pipe(debounceTime(500), takeUntil(this.destroy))
      .subscribe(() => this.refresh());
  }

  ngAfterViewInit(): void {
    this.refresh(true);
  }

  refresh(init: boolean = false) {
    this.width = this.elt.nativeElement.offsetWidth;
    let nbDay = 7;
    if (this.width < 273) {
      nbDay = 1;
    } else if (this.width < 375) {
      nbDay = 2;
    } else if (this.width < 550) {
      nbDay = 3;
    } else if (this.width < 750) {
      nbDay = 5;
    }
    if (init || nbDay !== this.nbDay) {
      this.nbDay = nbDay;
      this.param = {
        uuid: this.etalissmentOrProId,
        start: this.start,
        end: DateService.dateAfterDays(
          this.nbDay - 1,
          DateService.lastDayTime(this.start)
        ),
      };
      this.availableRdvPeriods();
    }
  }

  get canGoBack() {
    return this.start > DateService.trunk();
  }

  get emptyPeriods() {
    return this.periods?.every((p) => !p.periods?.length);
  }

  get showMoreBtn() {
    return this.periods?.some((p) => p.periods?.length > 5);
  }

  prev() {
    this.param.end = DateService.dateBeforeDays(
      1,
      DateService.lastDayTime(cloneDeep(this.start))
    );
    this.start = DateService.dateBeforeDays(this.nbDay, this.start);
    this.param.start = this.start;
    this.availableRdvPeriods();
  }

  next() {
    this.start = DateService.dateAfterDays(this.nbDay, this.start);
    this.param.start = this.start;
    this.param.end = DateService.dateAfterDays(
      this.nbDay - 1,
      DateService.lastDayTime(this.start)
    );
    this.availableRdvPeriods();
  }

  availableRdvPeriods() {
    this.loading.set(true);

    const ws = this.isPro
      ? this.publiqueService.availableMedecinRdvPeriods(this.param)
      : this.publiqueService.availableEtablissmentRdvPeriods(this.param);

    ws.pipe(
      debounceTime(500),
      take(1),
      tap(() => this.loading.set(false))
    ).subscribe((res) => (this.periods = res.data));
  }

  get moreBtnTitle(): string {
    return this.more ? 'rdv.moins-horaires' : 'rdv.plus-horaires';
  }

  toggleMoreTimes() {
    this.more = !this.more;
  }
}
