import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { IconName } from '@san/tools/models';
import { SanAutocompleteComponent } from '@san/tools/ui/autocomplete';
import { SanButtonIconComponent } from '@san/tools/ui/button-icon';
import { Subject } from 'rxjs';
import { PubliqueService } from '@shared/api/publique.service';
import {
  GlobalSearchTerm,
  SearchTerm,
} from '@shared/models/interfaces/app-conf.interface';
import { MapService } from '@shared/services/map.service';

@Component({
  selector: 'rdv-reservation-search',
  templateUrl: './reservation-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./reservation-search.component.scss'],
  imports: [
    CommonModule,
    FlexLayoutModule,
    SanAutocompleteComponent,
    SanButtonIconComponent,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class ReservationSearchComponent implements OnInit, AfterViewInit {
  private readonly mapService = inject(MapService);
  private readonly publiqueService = inject(PubliqueService);

  @Input() searchTerm: GlobalSearchTerm;
  readonly IconName = IconName;
  searchForm: FormGroup;
  taping: Subject<void> = new Subject<void>();
  termOptions: SearchTerm[] = this.publiqueService.termOptions;
  citiesOptions: SearchTerm[] = this.publiqueService.citiesOptions;
  @Output() searching: EventEmitter<GlobalSearchTerm> =
    new EventEmitter<GlobalSearchTerm>();
  @ViewChild('term', { static: true }) term: SanAutocompleteComponent;
  @ViewChild('city', { static: true }) city: SanAutocompleteComponent;

  ngOnInit(): void {
    this.searchForm = new FormGroup({
      term: new FormControl(
        this.searchTerm?.term?.id ? this.searchTerm.term : null
      ),
      city: new FormControl(
        this.searchTerm?.city?.id ? this.searchTerm.city : null
      ),
    });
  }

  ngAfterViewInit(): void {
    if (!this.searchTerm?.term?.id && this.searchTerm?.term?.libelle?.length) {
      this.term.selectedLabel = this.searchTerm.term.libelle;
    }
    if (!this.searchTerm?.city?.id && this.searchTerm?.city?.libelle?.length) {
      this.city.selectedLabel = this.searchTerm.city.libelle;
    }
  }

  locateMe() {
    this.mapService.findMe((pos: GeolocationPosition) => {
      const position = MapService.geolocationToLatLng(pos);
      this.searchForm.reset();
      this.searching.next({
        gps: {
          gps_lat: position.lat.toString(),
          gps_lng: position.lng.toString(),
        },
      });
    });
  }

  searchMedecin() {
    const data = this.searchForm.getRawValue();
    this.searching.next({
      term: data.term ?? { libelle: this.term.term, type: 'all' },
      city: data.city ?? { libelle: this.city.term, type: 'all' },
    });
  }
}
