import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  inject,
} from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { ActivatedRoute } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { IconName, ListModel } from '@san/tools/models';
import { SanLoaderDirective } from '@san/tools/shared';
import { SanBlocComponent } from '@san/tools/ui/bloc';
import { SanIconComponent } from '@san/tools/ui/icon';
import { SanListViewComponent } from '@san/tools/ui/list-view';
import { BaseComponent } from '@san/tools/utils';
import { get, has } from 'lodash-es';
import { HospitalComponent } from '../../hospital/hospital.component';
import { MedecinDiplomeComponent } from '../medecin-diplome/medecin-diplome.component';
import { UserInfoComponent } from '../user-info/user-info.component';
import { PubliqueService } from './../../../api/publique.service';
import { Utilisateur } from './../../../models/entity/Utilisateur';
import { Couverture } from './../../../models/entity/couverture';
import { Diplome } from './../../../models/entity/diplome';
import { Moyen } from './../../../models/entity/moyen';
import { Prestation } from './../../../models/entity/prestation';
import { Specialite } from './../../../models/entity/specialite';
import { ReactiveService } from './../../../services/reactive.service';
import { UserService } from './../../../services/user.service';
import { ObjectUtility } from './../../../utils/object.utils';
import { SafeHtmlPipe } from '@san/tools/pipes';

@Component({
  selector: 'rdv-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FlexLayoutModule,
    SanLoaderDirective,
    SanListViewComponent,
    SanBlocComponent,
    HospitalComponent,
    UserInfoComponent,
    SanIconComponent,
    TranslatePipe,
    SafeHtmlPipe,
    MedecinDiplomeComponent,
  ],
})
export class UserProfileComponent
  extends BaseComponent
  implements AfterViewInit
{
  private readonly publiqueService = inject(PubliqueService);
  private readonly userService = inject(UserService);
  private readonly route = inject(ActivatedRoute);
  public readonly reactiveService = inject(ReactiveService);

  readonly IconName = IconName;
  pro: Utilisateur;
  prestations: ListModel<string>[] = [];
  specialites: ListModel<number>[] = [];
  moyens: ListModel<number>[] = [];
  couvertures: ListModel<number>[] = [];
  diplomes: Diplome[] = [];
  isMedecin = this.userService.proIsConnected();

  ngAfterViewInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id?.length) {
      this.reactiveService.call(
        this.publiqueService.findMedecin(id),
        (res) => {
          this.pro = res.data;
          this.prestations = this.getListItems(
            this.pro.etablissement.prestations
          );
          this.diplomes = this.pro.medecin?.diplomes;
          this.specialites = this.getListItems(this.pro.medecin.specialites);
          this.moyens = this.getListItems(this.pro.etablissement.moyens);
          this.couvertures = this.getListItems(
            this.pro.etablissement.couvertures
          );
        },
        false
      );
    }
  }

  getListItems(
    items: (Specialite | Prestation | Moyen | Couverture)[]
  ): ListModel<never>[] {
    return items?.map(
      (it) =>
        ({
          label: has(it, 'tarif')
            ? `${it.libelle} - ${ObjectUtility.formatPrice(
                Number(get(it, 'tarif'))
              )}`
            : it.libelle,
          value: it.id,
          data: it,
        } as ListModel<never>)
    );
  }
}
