<div class="booking middle full-width">
  <h2 class="text-primary">{{'rdv.reservation' | translate}}</h2>
  <div class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">

    <ng-container *ngIf="step < RdvStep.RESUME">

      <san-bloc header="{{'account.medecin' | translate}} : {{header | uppercase}}" [opened]="false"
        [retractable]="true">
        <div class="full-width" fxLayout="column" content>
          @if(isMedecin(intervenant)){
          <div><strong>{{'account.name' | translate}}</strong> :
            <span>{{medecin.utilisateur?.nom | displayValue}}</span>
          </div>
          <div><strong>{{'account.firstname' | translate}}</strong> :
            <span>{{medecin.utilisateur?.prenoms| displayValue}}</span>
          </div>
          }
          <div class="font-bold">{{'profil-medecin.etablissement' | translate}}</div>
          <div class="small-margin">
            <rdv-hospital [etablissement]="medecin?.utilisateur?.etablissement ?? etablissement"></rdv-hospital>
          </div>
        </div>
      </san-bloc>

      <div *ngIf="step >= RdvStep.DATE" class="full-width">
        <san-bloc header="{{'shared.dateHeure' | translate}} : {{(date|longDate:true)|uppercase}}"
          [opened]="step === RdvStep.DATE" [retractable]="true" (statusChange)="refreshTimes($event)">
          <div class="full-width" content>
            <rdv-available-periods *ngIf="step === RdvStep.DATE" (clickOnTime)="changeDate($event);"
              [etalissmentOrProId]="intervenant.id" [isPro]="isPro"></rdv-available-periods>
          </div>
        </san-bloc>
      </div>

      <div *ngIf="step >= RdvStep.RAISON" class="full-width">
        <san-bloc header="{{'rdv.motif' | translate}}" [opened]="step === RdvStep.RAISON" [retractable]="true">
          <div class="full-width" content>
            <san-select idControl="rdv-booking-pestation" label="rdv.motif-rdv" placeholder="shared.selectionner"
              [required]="true" [options]="prestations" [(ngModel)]="prestation" [manageObject]="true"></san-select>
          </div>
        </san-bloc>
      </div>

    </ng-container>

    <div *ngIf="step === RdvStep.RESUME" class="full-width">
      <san-bloc [isLoading]="loading()" header="shared.resume">
        <div class="full-width" fxLayout="column" content>
          <div class="font-bold text-info">{{'shared.vos-informations' | translate}}</div>
          <div class="small-margin">
            <div><strong>{{'account.name' | translate}}</strong> : <span>{{patient?.nom | displayValue}}</span></div>
            <div><strong>{{'account.firstname' | translate}}</strong> : <span>{{patient?.prenoms | displayValue}}</span>
            </div>
          </div>
          <ng-container *ngIf="isMedecin(intervenant)">
            <div class="font-bold text-info">{{'account.medecin' | translate}}</div>
            <div class="small-margin">
              <div><strong>{{'account.name' | translate}}</strong> :
                <span>{{medecin.utilisateur?.nom | displayValue}}</span>
              </div>
              <div><strong>{{'account.firstname' | translate}}</strong> :
                <span>{{medecin.utilisateur?.prenoms | displayValue}}</span>
              </div>
            </div>
          </ng-container>
          <div class="font-bold text-info">{{'rdv.meeting' | translate}}</div>
          <div class="small-margin">
            <div><strong>{{'rdv.motif-rdv' | translate}}</strong> :
              <span>{{prestation?.label | displayValue}}</span>
            </div>
            <div><strong>{{'shared.dateHeure' | translate}}</strong> :
              <span>{{(date|longDate:true)|uppercase}}</span>
            </div>
          </div>
          <div class="font-bold text-info">{{'profil-medecin.etablissement' | translate}}</div>
          <div class="small-margin">
            <rdv-hospital [etablissement]="medecin?.utilisateur?.etablissement ?? etablissement"></rdv-hospital>
          </div>
          <san-checkbox idControl="rdv-booking-confirm-check" [(ngModel)]="confirm" label="rdv.confirm-rdv"
            labelPosition="after"></san-checkbox>
        </div>
      </san-bloc>
    </div>

    <div class="full-width" fxLayout="row" fxLayoutAlign="start center">
      <san-button-icon routerLink="/" color="warn" [icon]="IconName.CLOSE_CIRCLE" idControl="rdv-booking-leave"
        label="shared.quitter"></san-button-icon>

      <div class="app-spacer"></div>

      <san-button-icon color="accent" [disabled]="step === RdvStep.DATE" [icon]="IconName.ARROW_CIRCLE_LEFT"
        idControl="rdv-booking-previous" label="shared.precedent" (onclick)="changeStep(false)"></san-button-icon>

      <san-button-icon color="accent" [disabled]="!enableNextStep" *ngIf="step !== RdvStep.RESUME"
        [icon]="IconName.ARROW_CIRCLE_RIGHT" idControl="rdv-booking-next" label="shared.suivant"
        (onclick)="changeStep()"></san-button-icon>

      <san-button-icon *ngIf="step === RdvStep.RESUME" [disabled]="!(confirm && rdvIsValid)"
        [icon]="IconName.CHECK_CIRCLE" idControl="rdv-booking-confirm" label="shared.confirmer"
        (onclick)="book()"></san-button-icon>
    </div>
  </div>
</div>