<div class="search-pane full-width">
  <rdv-reservation-search [searchTerm]="searchTerm" (searching)="search($event)"></rdv-reservation-search>
  <div class="search-results full-width" [isLoading]="loading()" fxLayout="column" fxLayoutAlign="start start"
    fxLayoutGap="5px">

    <san-button-toggle [options]="tabs" [(ngModel)]="selectedTab"></san-button-toggle>

    <div [hidden]="selectedTab !== IconName.DOCTOR">
      @if(medecins.length){
      <rdv-reservation-bloc *ngFor="let pro of medecins;" [medecin]="pro"></rdv-reservation-bloc>
      <san-pagination [nbItems]="nbMedecins" (pageChange)="paginateMedecins($event)"></san-pagination>
      }@else {
      <ng-container [ngTemplateOutlet]="notFound"></ng-container>
      }
    </div>

    <div [hidden]="selectedTab !== IconName.HOSPITAL">
      @if(etablissements.length){
      <rdv-reservation-bloc *ngFor="let etablissement of etablissements;"
        [etablissement]="etablissement"></rdv-reservation-bloc>
      <san-pagination [nbItems]="nbEtablissements" (pageChange)="paginateEtablissements($event)"></san-pagination>
      }@else {
      <ng-container [ngTemplateOutlet]="notFound"></ng-container>
      }
    </div>

    <div class="full-width" *ngIf="selectedTab === IconName.MAP">
      <rdv-map width="100%" [makers]="mapEtablissements" [permanentTooltip]="true" clickMode="EventOnly"
        (centerChange)="searchOnMapMoved($event)"></rdv-map>
    </div>

  </div>
</div>

<ng-template #notFound>
  <div class="text-primary">
    <h3>{{'shared.aucunResultat' | translate}}</h3>
  </div>
</ng-template>