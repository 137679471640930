import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { IconName } from '@san/tools/models';
import { SanBlocComponent } from '@san/tools/ui/bloc';
import { Etablissement } from '../../models/entity/etablissement';
import { Medecin } from '../../models/entity/medecin';
import { RdvStep } from '../../models/enum/rdv.enum';
import { FileDestination } from '../../models/interfaces/file.interface';
import { NavigationService } from '../../services/navigation.service';
import { UserService } from '../../services/user.service';
import { AvailablePeriodsComponent } from '../available-periods/available-periods.component';
import { HospitalComponent } from '../hospital/hospital.component';
import { UserResumeComponent } from '../user/user-resume/user-resume.component';

@Component({
  selector: 'rdv-reservation-bloc',
  templateUrl: './reservation-bloc.component.html',
  styleUrls: ['./reservation-bloc.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    FlexLayoutModule,
    SanBlocComponent,
    UserResumeComponent,
    HospitalComponent,
    AvailablePeriodsComponent,
  ],
})
export class ReservationBlocComponent {
  private readonly navigationService = inject(NavigationService);

  pro: Medecin;

  @Input() etablissement: Etablissement;
  @Input() set medecin(medecin: Medecin) {
    this.pro = medecin;
    this.pro.utilisateur.medecin = medecin;
  }
  readonly IconName = IconName;
  readonly FileDestination = FileDestination;

  get isPro() {
    return !!this.pro?.id;
  }

  get header() {
    return this.isPro
      ? UserService.getFullName(this.pro.utilisateur)
      : this.etablissement.libelle;
  }

  get id() {
    return this.pro?.id ?? this.etablissement?.id?.toString();
  }

  openBooking(date: Date) {
    this.navigationService.openBooking(
      this.pro,
      this.isPro ? null : this.etablissement,
      RdvStep.RAISON,
      null,
      date
    );
  }
}
