import { Routes } from '@angular/router';
import { BaseMenu } from '@shared/models/enum/user-menu.enum';
import { BookingComponent } from './components/booking/booking.component';
import { DoctorDetailsComponent } from './components/doctor-details/doctor-details.component';
import { DoctorsComponent } from './components/doctors/doctors.component';
import { RdvFoundMedecinComponent } from './components/rdv-found-medecin/rdv-found-medecin.component';
import { SearchHomeComponent } from './components/search-home/search-home.component';
import { HomeComponent } from './home.component';

export const homeRoutes: Routes = [
  {
    path: '',
    component: HomeComponent,
    title: 'menu.accueil',
    children: [
      { path: '', redirectTo: `search`, pathMatch: 'full' },
      { path: 'search', component: SearchHomeComponent, title: 'menu.accueil' },
      {
        path: BaseMenu.DOCTOR,
        component: DoctorsComponent,
        title: 'menu.medecins',
      },
      {
        path: `${BaseMenu.DOCTOR}/:id`,
        component: DoctorDetailsComponent,
        title: 'menu.profil-pro',
      },
      {
        path: `${BaseMenu.RDV}/reservation`,
        component: RdvFoundMedecinComponent,
        title: 'menu.rdv',
      },
      {
        path: `${BaseMenu.RDV}/booking`,
        component: BookingComponent,
        title: 'menu.prendre-rdv',
      },
    ],
  },
];
