import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslatePipe } from '@ngx-translate/core';
import { ReservationSearchComponent } from '../reservation-search/reservation-search.component';
import { GlobalSearchTerm } from '@shared/models/interfaces/app-conf.interface';
import { SessionKey } from '@shared/models/session.keys';
import { ApplicationService } from '@shared/services/application.service';
import { NavigationService } from '@shared/services/navigation.service';
import { SsrService } from '@shared/services/ssr.service';

@Component({
  selector: 'rdv-search-home',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    ReservationSearchComponent,
    TranslatePipe,
    FlexLayoutModule,
  ],
  templateUrl: './search-home.component.html',
  styleUrl: './search-home.component.scss',
})
export class SearchHomeComponent {
  private readonly navigationService = inject(NavigationService);
  private readonly ssrService = inject(SsrService);

  searchMedecin(searchTerm: GlobalSearchTerm) {
    if (this.ssrService.isBrowser()) {
      ApplicationService.storeSession(
        SessionKey.MEDECIN_SEARCH_TERM,
        searchTerm
      );
    }
    this.navigationService.navigate('/home/rendez-vous/reservation');
  }
}
