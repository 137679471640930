<div class="doctors middle full-width">
  <h2 class="text-primary">{{'menu.medecins' | translate}}</h2>
  <p>
    <san-input idControl="search-medecin" placeholder="home.medecins.search-pl" [(ngModel)]="term"
      (ngModelChange)="searching.next()"></san-input>
  </p>
  <div [isLoading]="loading()" class="full-width" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="10px">
    @if (doctors?.length) {
    <div *ngFor="let medecin of doctors;let last= last;trackBy:templateService.trackByFn" class="full-width"
      fxLayout="column">
      <rdv-user-resume [pro]="medecin" [showRdvButton]="true"></rdv-user-resume>
      <div *ngIf="!last" class="san-divider"></div>
    </div>
    <san-pagination [nbItems]="totalItems" (pageChange)="search($event)"></san-pagination>
    } @else if(!loading()) {
    <div class="text-primary">
      <h3>{{'shared.aucunResultat' | translate}}</h3>
    </div>
    }
  </div>
</div>