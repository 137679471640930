import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserProfileComponent } from '@shared/components/user/profile/user-profile.component';

@Component({
  selector: 'rdv-doctor-details',
  templateUrl: './doctor-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./doctor-details.component.scss'],
  imports: [UserProfileComponent],
})
export class DoctorDetailsComponent {}
