import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import {
  IconName,
  Page,
  SearchCondition,
  SearchParam,
  SortDirection,
} from '@san/tools/models';
import { SanTemplateService } from '@san/tools/services';
import { SanLoaderDirective } from '@san/tools/shared';
import { SanInputComponent } from '@san/tools/ui/input';
import { SanPaginationComponent } from '@san/tools/ui/pagination';
import { BaseComponent, FilterUtils } from '@san/tools/utils';
import { Subject, debounceTime, take, takeUntil, tap } from 'rxjs';
import { PubliqueService } from '@shared/api/publique.service';
import { UserResumeComponent } from '@shared/components/user/user-resume/user-resume.component';
import { Utilisateur } from '@shared/models/entity/Utilisateur';

@Component({
  selector: 'rdv-doctors',
  templateUrl: './doctors.component.html',
  host: { ngSkipHydration: 'true' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./doctors.component.scss'],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    UserResumeComponent,
    SanInputComponent,
    SanPaginationComponent,
    SanLoaderDirective,
    TranslatePipe,
    FlexLayoutModule,
  ],
})
export class DoctorsComponent extends BaseComponent implements OnInit {
  private readonly publiqueService = inject(PubliqueService);
  public readonly templateService = inject(SanTemplateService);

  doctors: Utilisateur[] = [];
  readonly IconName = IconName;
  param: SearchParam = {};
  page: Page;
  totalItems: number = 0;
  term = '';
  loading = signal(true);
  searching: Subject<void> = new Subject<void>();

  buildParam() {
    const option = FilterUtils.generateFilter(SearchCondition.OR);
    const utils = new FilterUtils();
    if (this.term?.length) {
      option.filters.push(utils.stringFilter('nom').contains(this.term).get());
      option.filters.push(
        utils.stringFilter('prenoms').contains(this.term).get()
      );
      option.filters.push(
        utils.stringFilter('prestations.libelle').contains(this.term).get()
      );
      option.filters.push(
        utils.stringFilter('specialites.libelle').contains(this.term).get()
      );
      option.filters.push(
        utils.stringFilter('etablissement.libelle').contains(this.term).get()
      );
      option.filters.push(
        utils.stringFilter('ville.libelle').contains(this.term).get()
      );
      option.filters.push(
        utils.stringFilter('commune.libelle').contains(this.term).get()
      );
    }
    return option;
  }

  search(page: Page = Page.build()) {
    this.page = page;
    this.param.orders = [
      { field: 'nom', direction: SortDirection.ASC },
      { field: 'prenoms', direction: SortDirection.ASC },
    ];
    this.param.page = this.page;
    this.param.filter = this.buildParam();
    this.publiqueService
      .availableMedecins(this.param)
      .pipe(
        take(1),
        tap(() => this.loading.set(false))
      )
      .subscribe((res) => {
        this.totalItems = res.size ?? 0;
        this.doctors = res.data;
      });
  }

  ngOnInit(): void {
    this.searching
      .pipe(
        tap(() => this.loading.set(true)),
        debounceTime(500),
        takeUntil(this.destroy)
      )
      .subscribe(() => this.search());
  }
}
